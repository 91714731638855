<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogVisible"
      width="700px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="details">
        <span class="closeicon" @click="closebox()"
          ><i class="el-icon-error"></i
        ></span>
        <div class="wb-tit font18">修改密码</div>
        <div class="updatabox">
          <el-form
            :model="infoValidateForm"
            ref="infoValidateForm"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item label="当前手机号：" prop="phone">
              <el-input
                disabled=""
                v-model="infoValidateForm.phone"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="短信验证码:"
              prop="code"
              :rules="[{ required: true, message: '请输入验证码' }]"
            >
              <div class="verify-wrapper">
                <el-input
                  type="text"
                  maxlength="6"
                  placeholder="请输入验证码"
                  v-model="infoValidateForm.code"
                  style="width: 350px"
                ></el-input>
                <el-button
                  class="btn-orange"
                  :disabled="disabled"
                  @click="getCode"
                  >{{ valiBtn }}</el-button
                >
              </div>
            </el-form-item>
            <el-form-item
              label="设置新密码："
              prop="password1"
              :rules="[{ required: true, message: '请设置新密码' }]"
            >
              <el-input
                type="password"
                v-model="infoValidateForm.password1"
                placeholder="请设置新密码"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="重复新密码："
              prop="password2"
              :rules="[
                {
                  required: true,
                  validator: validatePass2,
                },
              ]"
            >
              <el-input
                type="password"
                v-model="infoValidateForm.password2"
                placeholder="请重复新密码"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="qux" @click="closebox()"
                >取消</el-button
              >
              <el-button
                type="primary"
                class="queding"
                @click.stop="submitForm('infoValidateForm')"
                >确定</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";

export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
        // password 是表单上绑定的字段
      } else if (value !== this.infoValidateForm.password1) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      validatePass2,
      infoValidateForm: {
        phone: "",
        code: "",
        password1: "",
        password2: "",
      },
      valiBtn: "获取验证码",
      disabled: false,
    };
  },
  mounted() {
    Bus.$on("psdwordstuts", (data) => {
      this.dialogVisible = data.show;
      this.infoValidateForm.phone = data.config.phone;
    });
  },
  methods: {
    closebox() {
      this.dialogVisible = false;
      // Bus.$emit('reportdstuts', false)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let config = {
            phone: this.infoValidateForm.phone,
            code: this.infoValidateForm.code,
            password: this.infoValidateForm.password1,
          };
          this.$api.changePass("post", config).then((res) => {
            if (res.code != 10200) {
              this.$message({
                message: res.message,
                type: "error",
              });
              return;
            }
            if (res.code == 10200) {
              this.$message({
                message: "密码修改成功，请重新登录！",
                type: "success",
              });
              localStorage.removeItem("token");
              this.$router.push({
                path: "/login",
                querry: {}, // 登录过期 回到登录页
              });
            }

            this.dialogVisible = false;
          });
        } else {
          return false;
        }
      });
    },
    //获取验证码 并只验证手机号 是否正确
    getCode() {
      this.$refs["infoValidateForm"].validateField("phone", (err) => {
        if (err) {
          return;
        } else {
          let fd = {
            phone: this.infoValidateForm.phone,
            type: 0,
          };
          this.$api.phonemsg("post", fd).then(() => {
            this.tackBtn();
          });
        }
      });
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.valiBtn = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.valiBtn = time + "秒后重发";
          time--;
        }
      }, 1000);
    },
  },
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  padding-bottom: 20px;
  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
  }
}

.updatabox {
  .demo-ruleForm {
    padding: 50px 80px 0px 50px;
    /deep/ .el-input__inner,
    /deep/ .el-textarea__inner {
      border-radius: 0px;
    }
    .qux {
      border-radius: 0px;
      width: 210px;
      // margin-top: 50px;
      background: #ffffff;
      border: 1px solid #00bcff;
      color: #00bcff;
      margin-right: 10px;
    }
    .queding {
      border-radius: 0px;
      width: 210px;
      // margin-top: 50px;
      background: #00bcff;
      border: none;
    }
    /deep/ .el-input-group__append,
    /deep/ .el-input-group__prepend {
      border-radius: 0 !important;
      background-color: #dddddd;
      color: #333333;
    }
  }
}

.verify-wrapper {
  display: flex;
  align-items: center;
  .btn-orange {
    width: 200px;
    padding: 13px 20px;
    background-color: #00bcff;
    border: none;
    color: #ffffff;
    margin-left: 30px;
    border-radius: 0;
  }
}
</style>
