<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogVisible"
      width="700px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="details">
        <span class="closeicon" @click="closebox()"
          ><i class="el-icon-error"></i
        ></span>
        <div class="wb-tit font18">上传在线简历</div>
        <div class="updatabox">
          <el-form
            v-if="type == 1"
            :model="picForm"
            ref="picForm"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="上传方式"
              prop="type"
              :rules="[{ required: true, message: '请选择上传方式' }]"
            >
              <el-radio-group v-model="picForm.type">
                <el-radio :label="1">51Job上传</el-radio>
                <el-radio :label="2">拉钩上传</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="请上传简历:"
              prop="picUrl"
              :rules="[
                { required: true, message: '请上传拉钩或51Job图片简历' },
              ]"
            >
              <el-upload
                class="avatar-uploader"
                action="https://freemen.work/user/file/upload/"
                :show-file-list="false"
                accept="image/jpeg,image/png,image/vnd.svf,image/tiff"
                :on-success="handlePicSuccess"
              ><!-- action="http://192.168.10.174:8200/user/file/upload/"  action="https://freemen.work/user/file/upload/" -->
                <img
                  v-if="picForm.picUrl"
                  :src="'https://freemen.work/user/file/view/' + picForm.picUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <div class="el-upload__tip font14" slot="tip">
                  只能上传jpg/png格式文件，文件不能超过3M
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item style="margin-top: 30px">
              <el-button type="primary" class="qux" @click="closebox()"
                >取消</el-button
              >
              <el-button
                type="primary"
                class="queding"
                @click="submitForm('picForm')"
                >确定</el-button
              >
            </el-form-item>
          </el-form>
          <el-form
            v-if="type == 2"
            :model="docForm"
            ref="docForm"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="请上传简历:"
              prop="docUrl"
              :rules="[{ required: true, message: '请上传猎聘网doc简历' }]"
            >
              <el-upload
                class="avatar-uploader"
                action="https://freemen.work/user/file/upload/"
                :show-file-list="false"
                accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                :on-success="handleDocSuccess"
              >
                <span v-if="docForm.docUrl">上传成功</span>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <div class="el-upload__tip font14" slot="tip">
                  只能上传doc格式文件，文件不能超过3M
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item style="margin-top: 30px">
              <el-button type="primary" class="qux" @click="closebox()"
                >取消</el-button
              >
              <el-button
                type="primary"
                class="queding"
                @click="submitForm('docForm')"
                >确定</el-button
              >
            </el-form-item>
          </el-form>
          <el-form
            v-if="type == 3"
            :model="addressForm"
            ref="addressForm"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="上传方式"
              prop="type"
              :rules="[{ required: true, message: '请选择上传方式' }]"
            >
              <el-radio-group v-model="addressForm.type">
                <el-radio :label="1">boss上传</el-radio>
                <el-radio :label="2">智联上传</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="在线简历地址:"
              prop="addressUrl"
              :rules="[
                {
                  required: true,
                  message: '请输入boss直聘和智联招聘在线简历地址',
                },
              ]"
            >
              <el-input
                v-model="addressForm.addressUrl"
                placeholder="请输入boss直聘和智联招聘在线简历地址"
              >
              </el-input>
            </el-form-item>
            <el-form-item style="margin-top: 30px">
              <el-button type="primary" class="qux" @click="closebox()"
                >取消</el-button
              >
              <el-button
                type="primary"
                class="queding"
                @click="submitForm('addressForm')"
                >确定</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
<!--    <iframe  style="display: none" ref="iframe" name="iframe1" id="iframe1"-->
<!--             :src="addressForm.addressUrl"></iframe>-->
<!--    <img src="">-->
  </div>
</template>

<script>
import Bus from "../../../common/bus.js";
import {$http} from "@/utils/requestfu";

export default {
  data() {
    return {
      dialogVisible: false,
      picForm: {
        type: 1,
        picUrl: "",
      },
      docForm: {
        docUrl: "",
      },
      addressForm: {
        type: 1,
        addressUrl: "",
      },
      type: "",
    };
  },
  watch: {
    dialogVisible(value) {
      this.picForm = {
        type: 1,
        picUrl: "",
      };
      this.docForm = {
        docUrl: "",
      };
      this.addressForm = {
        type: 1,
        addressUrl: "",
      };
      if (!value) {
        if (this.type == 1) {
          this.$refs.picForm.clearValidate();
        } else if (this.type == 2) {
          this.$refs.docForm.clearValidate();
        } else {
          this.$refs.addressForm.clearValidate();
        }
      }
    },
  },
  mounted() {
    Bus.$on("resumestuts", (data) => {
      this.dialogVisible = data.show;
      this.type = data.type;
    });
  },
  methods: {
    closebox() {
      this.dialogVisible = false;
      // Bus.$emit('reportdstuts', false)
    },
    handleDocSuccess(response, file) {
      // console.log(response, file);
      this.docForm.docUrl = response.data;
    },
    handlePicSuccess(response, file) {
      // console.log(response, file);
      this.picForm.picUrl = response.data;
    },
    getParam(url,name){
      return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(url)||["",""])[1])||null;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.type == 1) {
            if (this.picForm.type == 1) {
              let uploadConfig = {
                type: 4,
                url: this.picForm.picUrl,
              };

              this.$api.pic51Resume("post", uploadConfig).then((res) => {
                this.dialogVisible = false;
                if (res.code == 10200) {
                  // console.log("$upload resume ------ $api.pic51Resume.res = ", res);
                  this.$message({
                    message: "识别完成",
                    type: "success",
                  });
                  let uploadResume = {
                    type: 4,
                    resumeInfo : res.data,
                  }
                  sessionStorage.setItem("uploadResume",JSON.stringify(uploadResume));
                  window.location.reload();

                  // this.$api.saveResume("post", res.data).then((res1) => {
                  //   if (res1.code == 10200) {
                  //     this.$message({
                  //       message: "完成",
                  //       type: "success",
                  //     });
                  //     window.location.reload();
                  //   }
                  // });
                } else {
                  this.$message({
                    message: res.message,
                    type: "error",
                  });
                }
              });
            } else { // 拉钩
              let uploadConfig = {
                type: 2,
                url: this.picForm.picUrl,
              };
              this.$api.picLjResume("post", uploadConfig).then((res) => {
                this.dialogVisible = false;
                if (res.code == 10200) {
                  // console.log("$upload resume ------ $api.picLjResume.res = ", res);
                  this.$message({
                    message: "识别完成",
                    type: "success",
                  });
                  let uploadResume = {
                    type: 2,
                    resumeInfo : res.data,
                  }
                  sessionStorage.setItem("uploadResume",JSON.stringify(uploadResume));
                  window.location.reload();

                  // this.$api.saveResume("post", res.data).then((res1) => {
                  //   if (res1.code == 10200) {
                  //     this.$message({
                  //       message: "完成",
                  //       type: "success",
                  //     });
                  //     window.location.reload();
                  //   }
                  // });
                } else {
                  this.$message({
                    message: res.message,
                    type: "error",
                  });
                }
              });
            }
          } else if (this.type == 2) {
            let uploadConfig = {
              type: 5,
              url: this.docForm.docUrl,
            };
            this.$api.docLpResume("post", uploadConfig).then((res) => {
              this.dialogVisible = false;
              if (res.code == 10200) {
                console.log("$upload resume ------ $api.docLpResume.res = ", res);
                this.$message({
                  message: "识别完成",
                  type: "success",
                });
                let uploadResume = {
                  type: 5,
                  resumeInfo : res.data,
                }
                sessionStorage.setItem("uploadResume",JSON.stringify(uploadResume));
                window.location.reload();

                // this.$api.saveResume("post", res.data).then((res1) => {
                //   if (res1.code == 10200) {
                //     this.$message({
                //       message: "完成",
                //       type: "success",
                //     });
                //     window.location.reload();
                //   }
                // });
              } else {
                this.$message({
                  message: res.message,
                  type: "error",
                });
              }
            });
          } else {
            if (this.addressForm.type === 1) {
              // https://m.zhipin.com/mpa/html/resume-detail?sid=self&securityId=CySj-nKVw6AOd-u1YUIHokmzNKmdOgN5ZJ4asLz2HwKVNVRX3xLbFomqME6-2yybR4_Q68RGXtpW95O3x5M36GTFUFq9H0wqW4ikzBuJ3Zqd95ndK2Zhq1hNd8nDk_wtMH1yHJT7GxJllQL10KCvfn_ce10QbRo25co~
              // https://m.zhipin.com/wapi/zpgeek/resume/share/data.json

              // this.$api.zhipin(
              //   "zhipin",
              //   "?sid=self&securityId=CySj-nKVw6AOd-u1YUIHokmzNKmdOgN5ZJ4asLz2HwKVNVRX3xLbFomqME6-2yybR4_Q68RGXtpW95O3x5M36GTFUFq9H0wqW4ikzBuJ3Zqd95ndK2Zhq1hNd8nDk_wtMH1yHJT7GxJllQL10KCvfn_ce10QbRo25co~"
              // );

              // 智联简历数据
              // https://m.zhaopin.com/api/capi
              // 请求方式：get
              // 参数：
              // capiUrl：resume/preview（固定值）
              // rt：rt（固定值）
              // at：at（固定值）
              // resumeNumber：获取输入的简历地址中的resumeNum参数对应的值
              // this.$api.zhilian("zhilian", {
              //   capiUrl: "resume/preview",
              //   rt: "rt",
              //   at: "at",
              //   resumeNumber: "",
              // });
              let tempUrl = "https://job.freemen.work/user/zhipin/data.json?securityId="+this.getParam(this.addressForm.addressUrl,"securityId");
              // let tempUrl = "http://192.168.10.174:8200/user/zhipin/data.json?securityId="+this.getParam(this.addressForm.addressUrl,"securityId");
              // console.log("UP resume----- tempUrl = ", tempUrl);
              // console.log("UP resume----- tempUrlLoc = ", tempUrlLoc);
              // let tempUrl = "" + this.addressForm.addressUrl;
              try {
                // try 前端访问 url 获取json
                $http.get(tempUrl).then(res=>{
                  // console.log("HTTP request, res = ". res);
                  let param = {
                    type: 1,
                    // url: tempUrl,
                    jsonResult: JSON.stringify(res.zpData||res.data0.zpData),
                  };
                  this.$api.addressBossResume("post", param).then((res1) => {
                    if (res1.code == 10200) {
                      this.dialogVisible = false;
                      // console.log("$upload resume ------ $api.addressBossResume.res = ", res);
                      this.$message({
                        message: "解析完成",
                        type: "success",
                      });

                      let uploadResume = {
                        type: 1,
                        resumeInfo : res1.data,
                      }
                      sessionStorage.setItem("uploadResume",JSON.stringify(uploadResume));
                      window.location.reload();

                      // this.$api.saveResume("post", res1.data).then((res2) => {
                      //   if (res2.code == 10200) {
                      //     this.$message({
                      //       message: "完成",
                      //       type: "success",
                      //     });
                      //     window.location.reload();
                      //   }
                      // });
                    }
                  });
                }).catch(e =>{
                  // console.log("$http failed, error = ", e, ", do post way!");
                  // 直接传值url给后台处理
                  let param = {
                    type: 1,
                    url: this.addressForm.addressUrl,
                  };
                  this.$api.addressBossUrl("post", param).then((res2) => {
                    if (res2.code === 10200) {
                      this.dialogVisible = false;
                      // console.log("$upload resume ------ $api.addressBossResume.res = ", res);
                      this.$message({
                        message: "解析完成",
                        type: "success",
                      });

                      let uploadResume = {
                        type: 1,
                        resumeInfo : res2.data,
                      }
                      sessionStorage.setItem("uploadResume",JSON.stringify(uploadResume));
                      window.location.reload();
                    } else {
                      this.$message({
                        message: "识别失败",
                        type: "error",
                      });
                    }
                  });
                });
              }catch (e){
                console.log("操作失败! ", e);
                this.$message({
                  message: "识别失败",
                  type: "error",
                });
              }

              // this.$api.addressBossResume("post", uploadConfig).then((res) => {
              //   this.dialogVisible = false;
              //   if (res.code == 10200) {
              //     this.$message({
              //       message: "上传成功",
              //       type: "success",
              //     });
              //   } else {
              //     this.$message({
              //       message: res.message,
              //       type: "error",
              //     });
              //   }
              // });
            } else {//智联
              let uploadConfig = {
                type: 3,
                url: this.addressForm.addressUrl,
              };
              this.$api.addressZlResume2("post", uploadConfig).then((res) => {
                this.dialogVisible = false;
                if (res.code == 10200) {

                  // console.log("$upload resume ------ $api.addressZlResume2.res = ", res);
                  this.$message({
                    message: "解析完成",
                    type: "success",
                  });

                  let uploadResume = {
                    type: 3,
                    resumeInfo : res.data,
                  }
                  sessionStorage.setItem("uploadResume",JSON.stringify(uploadResume));
                  // window.location.reload();
                } else {
                  this.$message({
                    message: res.message,
                    type: "error",
                  });
                }
              });
            }
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  padding-bottom: 20px;
  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
  }
}

.updatabox {
  .demo-ruleForm {
    padding: 50px 80px 0px 50px;
    /deep/ .el-input__inner,
    /deep/ .el-textarea__inner {
      border-radius: 0px;
    }
    .qux {
      border-radius: 0px;
      width: 210px;
      // margin-top: 50px;
      background: #ffffff;
      border: 1px solid #00bcff;
      color: #00bcff;
      margin-right: 10px;
    }
    .queding {
      border-radius: 0px;
      width: 210px;
      // margin-top: 50px;
      background: #00bcff;
      border: none;
    }
    /deep/ .el-input-group__append,
    /deep/ .el-input-group__prepend {
      border-radius: 0 !important;
      background-color: #dddddd;
      color: #333333;
    }
  }
}

.verify-wrapper {
  display: flex;
  align-items: center;
  .btn-orange {
    width: 200px;
    padding: 13px 20px;
    background-color: #00bcff;
    border: none;
    color: #ffffff;
    margin-left: 30px;
    border-radius: 0;
  }
}
</style>
