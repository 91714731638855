<template>
	<div class="">
		<el-dialog :visible.sync="dialogVisible" width="600px" center class="delete_box" :show-close="false" :modal-append-to-body="false" :close-on-click-modal="false">
			<div class="wb-tit font18">编辑</div>
			<div class="" style="padding: 50px 100px 30px 100px;text-align: center;position: relative;">
				<span class="closeicon" @click="dialogVisible = false"><i class="el-icon-error"></i></span>
				<div class="input-box">
					<el-input v-model="editConfig.content" placeholder="请输入常用语"></el-input>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button class="qx" @click="dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="save">保存</el-button>
				</span>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Bus from '../../common/bus.js';
export default {
	data() {
		return {
			dialogVisible: false,
			editConfig:{
				id:'',
				content:'',
				orderNum:'',
			},
			callback:null
		};
	},
	mounted() {
		Bus.$on('editDialogstuts', data => {
			this.dialogVisible = data.show;
			this.editConfig.id = data.config.id
			this.editConfig.content = data.config.content
			this.editConfig.orderNum = data.config.orderNum
			this.callback = data.callback
		});
	},
	methods: {
		save(){
			let config = [this.editConfig]
			this.$api.savePhrase('post',config).then(()=>{
				this.dialogVisible = false;
				this.callback()
			})
		},
		closebox() {
			this.dialogVisible = false;
			// Bus.$emit('reportdstuts', false)
		},
		
	}
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
	padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
	position: absolute;
	top: -125px;
	right: 0;
	font-size: 26px;
	color: #f3f3f3;
}


/deep/ .el-textarea__inner {
			border-radius: 0px;
		}

.delete_box{
		line-height: 4;
		padding: 30px 0;
		// position: relative;
		// /deep/.el-card__body{
		// 	padding: 50px 30px 30px 30px !important;
		// }
		.wb-tit {
			padding: 17px 0;
			text-align: center;
			background-color: #f3f3f3;
			color: #000000;
			font-weight: 600;
			line-height: 1;
		}
		.input-box{
			/deep/ .el-input__inner{
				border-radius: 0px;
			}
		}
		.db-ts{
			text-align: center;
			color: #666666;
			line-height: 1.5;
		}
		.dialog-footer{
			
			/deep/ .el-input__inner{
				border-radius: 0px;
			}
			/deep/ .el-button{
				margin-top: 40px;
				border-radius: 0px;
				background: #00bcff;
				border: none;
				width: 190px;
				height: 40px;
			}
			.qx{
				background-color: #fff;
				border: 1px solid #00bcff;
				color: #00bcff;
				margin-right: 10px;
			}
		}
	}



</style>
