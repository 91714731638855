<template>
  <el-container style="height: 100%; overflow: auto">
    <el-header><navtop></navtop></el-header>
    <el-main>
      <div
        class="contentmain"
        style="margin-top: 20px"
        :style="{ 'min-height': sheight + 'px' }"
        ref="pronbit"
      >
        <el-row class="tac" style="margin-bottom: 80px; position: relative">
          <el-col :span="5" style="z-index: 10">
            <el-menu
              class="el-menu-vertical-demo nav-lrft menubar"
              text-color="#111111"
              :default-active="tabindex"
              active-text-color="#00BCFF"
              @open="handleOpen"
              @close="handleClose"
              unique-opened
              style="width: 240px; position: fixed"
            >
              <el-menu-item index="1" @click="gotonext('/myfree', '1')"
                ><span slot="title">我的Freemen</span></el-menu-item
              >
              <el-submenu index="2">
                <template slot="title">
                  <span>简历中心</span>
                </template>
                <el-menu-item-group class="tebie">
                  <el-menu-item
                    index="1-1"
                    @click="pagegotonext('/information', '1-1', 'gerenname')"
                    >个人信息</el-menu-item
                  >
                  <el-menu-item
                    index="1-2"
                    @click="pagegotonext('/information', '1-2', 'qiuzhi')"
                    >求职意向</el-menu-item
                  >
                  <el-menu-item
                    index="1-3"
                    @click="pagegotonext('/information', '1-3', 'gzjl')"
                    >工作经历</el-menu-item
                  >
                  <el-menu-item
                    index="1-4"
                    @click="pagegotonext('/information', '1-4', 'jyjl')"
                    >教育经历</el-menu-item
                  >
                  <el-menu-item
                    index="1-5"
                    @click="pagegotonext('/information', '1-5', 'zgzs')"
                    >获得证书</el-menu-item
                  >
                  <el-menu-item
                    index="1-6"
                    @click="pagegotonext('/information', '1-6', 'waiyu')"
                    >外语能力</el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item index="3" @click="gotonext('/deliveryrecord', '3')"
                ><span slot="title">投递记录</span></el-menu-item
              >
              <el-menu-item
                index="4"
                @click="gotonext('/Interviewmanagement', '4')"
                ><span slot="title">面试管理</span></el-menu-item
              >
              <el-menu-item index="5" @click="gotonext('/offermanagement', '5')"
                ><span slot="title">offer管理</span></el-menu-item
              >
              <el-menu-item index="6" @click="gotonext('/securityset', '6')"
                ><span slot="title">安全设置</span></el-menu-item
              >
              <el-menu-item index="7" @click="gotonext('/commonwords', '7')"
                ><span slot="title">常用语</span></el-menu-item
              >
              <el-menu-item index="8" @click="gotonext('/shieldcompany', '8')"
                ><span slot="title">屏蔽公司</span></el-menu-item
              >
              <el-menu-item index="9" @click="gotonext('/realname', '9')"
                ><span slot="title">实名认证</span></el-menu-item
              >
              <el-menu-item index="10" @click="gotonext('/help', '10')"
                ><span slot="title">反馈与帮助</span></el-menu-item
              >
            </el-menu>
          </el-col>
          <el-col :span="19" style="float: right">
            <div class="center-content" style="padding-left: 10px">
              <router-view></router-view>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- <footerbottom></footerbottom> -->
    </el-main>
    <!-- 上传简历弹窗 -->
    <upresume></upresume>
    <!-- 选择第三方 -->
    <choosefang></choosefang>

    <!-- 删除弹窗 -->
    <deleteD></deleteD>
    <!-- 修改头像 -->
    <modifyavatar></modifyavatar>
    <!-- 选择外语能力 -->
    <chooselangage></chooselangage>

    <!-- 查看证书 -->
    <lookbook></lookbook>
    <!-- 选择工作技能 -->
    <choosejineng></choosejineng>

    <!-- 修改密码 -->
    <changepassword></changepassword>
    <!-- 修改手机号 -->
    <changephone></changephone>
    <!-- 添加常用语弹窗 -->
    <addword></addword>
    <!-- 编辑常用弹窗 -->
    <deletechang></deletechang>
    <!-- 删除常用弹窗 -->
    <shangchuchang></shangchuchang>
  </el-container>
</template>

<script>
import modifyavatar from "../person/modifyavatar.vue";
// import footerbottom from "../public/footerbottom.vue";
import navtop from "../public/navtop.vue";
import addword from "./addword.vue";
import changepassword from "./changepassword.vue";
import changephone from "./changephone.vue";
import deletechang from "./deletechang.vue";
import choosefang from "./personal/choosefang.vue";
import choosejineng from "./personal/choosejineng.vue";
import chooselangage from "./personal/chooselangage.vue";
import deleteD from "./personal/deleteD.vue";
import lookbook from "./personal/lookbook.vue";
import upresume from "./personal/upresume.vue";
import shangchuchang from "./shangchuchang.vue";
export default {
  components: {
    navtop,
    // footerbottom,
    upresume,
    choosefang,
    deleteD,
    modifyavatar,
    lookbook,
    chooselangage,
    choosejineng,
    changepassword,
    changephone,
    addword,
    deletechang,
    shangchuchang,
  },
  data() {
    return {
      tabindex: "1",
      sheight: "",
      headerstuts: false,
    };
  },
  created() {
    this.sheight = document.documentElement.scrollHeight - 80;
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollx, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScrollx, true);
  },
  methods: {
    handleScrollx() {
      // console.log('滚动高度',window.pageYOffset)
      // console.log('距离顶部高度',this.$refs.pronbit.getBoundingClientRect().top)
      this.rollheight = this.$refs.pronbit.getBoundingClientRect().top;
      // if(this.rollheight<0){
      // 	this.headerstuts=true;
      // 	console.log('头部隐藏出来',this.headerstuts,this.rollheight)
      // }else{
      // 	this.headerstuts=false;
      // }
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    gotonext(path, val) {
      // console.log('进入了跳转',path,this.$route.path)
      this.tabindex = val;
      this.$router.push(path);
      // this.$router.push('/deliveryrecord');
    },
    pagegotonext(path, val, name) {
      // console.log("进入了跳转", this.$route.path, name);
      this.tabindex = val;
      if (this.$route.path == "/information") {
        localStorage.setItem("yulanname", name);
        let names = localStorage.getItem("yulanname");
        // console.log("改变后的预览", namees);
        this.$emit("lallal", names);
      } else {
        this.$router.push(path);
      }
    },
    handleCommand(command) {
      switch (command) {
        case "logout":
          this.loginout();
          break;
      }
    },
    loginout() {
      this.$api.outLogin("post").then(() => {
        window.sessionStorage.clear();
        this.$IM.outLogin();
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.menubar{
  overflow-y: auto;
  height: calc(100vh - 90px);
}
/deep/.el-submenu,
/deep/.el-menu-item {
  padding: 0;
}
// /deep/.is-active{
// 	position: relative;
// }
// /deep/.is-active :after{
// 	position: absolute;
// 	content: '';
// 	width: 4px;
// 	height: 12px;
// 	bottom: 50%;
// 	background: #00BCFF;
// 	left: 2px;
// 	transform: translate(-50%, 60%);
// }
.tebie /deep/.el-menu-item {
  padding-left: 40px !important;
  color: #666666 !important;
}
.tebie /deep/.is-active {
  color: #00bcff !important;
}

.content {
  padding: 20px 0 200px 0;
}
.center-content {
  // height: 600px;
  // background-color: #0000FF;
}

.el-header {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  height: 56px !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1) !important;
}
.el-footer {
  height: 352px !important;
  padding: 0 0 !important;
  margin: 0 0 !important;
}
.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #f3f3f3;
  // background-color: #0000FF;
  margin-top: 56px;
  padding: 0;
}
.nav-lrft {
  // position: fixed;
  // top: 0;
}
</style>
