<template>
	<div class="">
		<el-dialog :visible.sync="dialogVisible" width="600px" :show-close="false" :modal-append-to-body="false" :lock-scroll="false">
			<div class="details">
				<!-- <el-carousel :interval="4000" type="card" height="200px">
					<el-carousel-item v-for="item in urlList" :key="item">
						<img :src="'https://freemen.work/user/file/view/'+item" >
					</el-carousel-item>
				</el-carousel> -->
				<div type="card" style="display: flex; align-items: center;justify-content: center;">
					<div v-for="item in urlList" :key="item">
						<img style="height:200px" :src="'https://freemen.work/user/file/view/'+item" >
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Bus from '../../../common/bus.js';
export default {
	data() {
		return {
			dialogVisible: false,
			content: '',
			urlList:[],
		};
	},
	mounted() {
		Bus.$on('lookbookstuts', data => {
      // console.log(22222)
			this.dialogVisible = data.show;
			this.urlList = data.urls.split(',')
		});
	},
	methods: {
		closebox() {
			this.dialogVisible = false;
		}
	}
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
	padding: 0 !important;
}
</style>
<style lang="less" scoped>
.details {
	padding: 60px 40px;
}

.el-carousel__item h3 {
	color: #475669;
	font-size: 14px;
	opacity: 0.75;
	line-height: 200px;
	margin: 0;
}

.el-carousel__item:nth-child(2n) {
	background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
	background-color: #d3dce6;
}
</style>
