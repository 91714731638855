<template>
	<div class="">
		<el-dialog :visible.sync="dialogVisible" class="delete_box" width="600px" :show-close="false" :modal-append-to-body="false" :lock-scroll="false">
			<div class="details">
				<!-- <span class="closeicon" @click="closebox()"><i class="el-icon-error"></i></span> -->
				<div class="db-img font18"><img src="../../../assets/images/set/shanchu.png" /> 确定要删除这条信息吗？</div>
				<!-- <div class="db-sure font22"></div> -->
				<div class="db-ts font16">删除后不能恢复，是否确认删除</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="commit">确定</el-button>
				</span>

			</div>
		</el-dialog>
	</div>
</template>

<script>
import Bus from '../../../common/bus.js';
export default {
	data() {
		return {
			dialogVisible: false,
			content:'',
			callback:null
		};
	},
	mounted() {
		Bus.$on('deletestuts', data => {
			// console.log('添加接受到', data);
			this.dialogVisible = data.show;
			this.callback = data.callback
			// this.id= data.id;
		});
	},
	methods: {
		closebox() {
			this.dialogVisible = false;
			// Bus.$emit('reportdstuts', false)
		},
		commit(){
			this.dialogVisible = false;
			this.callback()
		}
	}
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
	padding: 0 !important;
}
</style>
<style lang="less" scoped>
	.delete_box{
		line-height: 4;
		.db-img{
			display: flex;
			align-items: center;
			justify-content: center;
			color: #111111;
			img{
				width: 30px;
				height: 30px;
				margin-right: 20px;
			}
		}
		.db-sure{
			text-align: center;
			color: #111111;
			line-height: 1.5;
		}
		.db-ts{
			text-align: center;
			color: #666666;
			line-height: 1.5;
			// margin-top: 10px;
		}
		.dialog-footer{
			margin-top: 20px;
			/deep/ .el-input__inner{
				border-radius: 0px;
			}
			/deep/ .el-button{
				border-radius: 0px;
			}
			button{
				width: 200px;
				height: 40px;
			}
		}
	}




.closeicon {
	position: absolute;
	top: -40px;
	right: 0;
	font-size: 26px;
	color: #f3f3f3;
}
.details {
	padding: 50px 50px 50px 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}







</style>
