<template>
	<div class="">
		<el-dialog :visible.sync="dialogVisible" width="700px" :show-close="false" :modal-append-to-body="false" :lock-scroll="false">
			<div class="details">
				<span class="closeicon" @click="closebox()">
					<i class="el-icon-error"></i>
				</span>
				<!-- vue-cropper与element ui实现头像图片剪切上传   未做 -->
				<div class="wb-tit font18">
					<div class="wb-left">
						选择外语能力
					</div>
					<div class="wb-right">
						<el-button type="primary" @click="submitForm('infoForm')">确定</el-button>
					</div>
				</div>
				<div class="c-main">
					<div class="cm-left">
						<div class="c-title t-active">
							英语类
						</div>
						<div class="c-title">
							外语类
						</div>
						<div class="c-title">
							IT类
						</div>
						<div class="c-title">
							会计类
						</div>
					</div>
					<div class="cm-right-box">
						<div class="cm-right">
							<span>大学英语四级</span>
							<span>大学英语六级</span>
							<span>雅思学术类高分者</span>
							<span>雅思学术类高分者</span>
							<span>上海外语口译证书</span>
							<span>上海外语口译证书</span>
							<span>OETC专业八级</span>
							<span>OETC专业八级</span>
							<span>托福学术类高分者</span>
							<span>托福学术类高分者</span>
						</div>
						
					</div>
					
				</div>
				
				
				
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Bus from "../../../common/bus.js"
export default {
	data() {
		return {
			dialogVisible: false,
			imageUrl: '',
			
			
			
			
			
			
		};
	},
	mounted() {
		Bus.$on('langagestuts', (data) => {
			
			this.dialogVisible=data;
		})
	},
	methods: {
		closebox(){
			this.dialogVisible=false;
			Bus.$emit('langagestuts', false)
		},
		handleAvatarSuccess(res, file) {
			this.imageUrl = URL.createObjectURL(file.raw);
		},
		beforeAvatarUpload(file) {
			console.log(file)
			// const isJPG = file.type === 'image/jpeg';
			// const isLt2M = file.size / 1024 / 1024 < 2;
		
			// if (!isJPG) {
			// 	this.$message.error('上传头像图片只能是 JPG 格式!');
			// }
			// if (!isLt2M) {
			// 	this.$message.error('上传头像图片大小不能超过 2MB!');
			// }
			// return isJPG && isLt2M;
		},
		
		
		
		
		
		
		
		
	}
};
</script>

<style type="text/css">
	.el-dialog__header,
	.el-dialog__body {
		padding: 0 !important;
	}
	
	
</style>
<style lang="less" scoped>
.closeicon{
		position: absolute;
		top:-40px;
		right: 0;
		font-size:26px;
		color: #F3F3F3;
	}
.details{
	.wb-tit{
			padding: 20px 30px;
			background-color: #FFFFFF;
			border-bottom: 1px solid #F3F3F3;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.wb-left{
				color: #111111;
			}
			.wb-right{
				/deep/ .el-button {
					border-radius: 0px;
					width: 104px;
					height: 44px;
					
					background: #00bcff;
					border: none;
				}
			}
		}
		
	
	
	
}
	
.c-main{
	display: flex;
	
	min-height: 400px;
	.cm-left{
		width: 270px;
		background-color: #FFFFFF;
		padding-top: 30px;
		.c-title{
			color: #111111;
			font-size: 18px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.t-active{
			color: #00BCFF;
			font-weight: 600;
			background-color: #F8F7FC;
		}
		.t-active:before{
				position: absolute;
				content: '';
				width: 4px;
				height: 28px;
				// top: 0;
				background: #00BCFF;
				left: 0px;
				transform: translate(0%, 0%);
			
		}
	}
	.cm-right-box{
		display: flex;
		flex: 1;
		background-color: #F8F7FC;
		padding-top: 30px;
		.cm-right{
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			width: 100%;
			height: 240px;
			span{
				width: 50%;
				line-height: 20px;
				text-align: center;
				color: #111111;
				font-size: 16px;
			}
			.cr-main{
				width: 200px;
				color: #111111;
				height: 50px !important;
			}
		}
	}
	
	
}
	
	
	
	
	


</style>
