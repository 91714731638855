<template>
	<div class="">
		<el-dialog :visible.sync="dialogVisible" width="700px" :show-close="false" :modal-append-to-body="false" :lock-scroll="false">
			<div class="details">
				<span class="closeicon" @click="closebox()"><i class="el-icon-error"></i></span>
				<div class="wb-tit font18">修改手机号</div>
				<div class="updatabox">
					<el-form :model="changePhoneForm" ref="changePhoneForm" label-width="130px" class="demo-ruleForm">
						<el-form-item label="当前手机号：" >
							<el-input  v-model="oldPhone" disabled="" placeholder="请输入手机号"></el-input>
						</el-form-item>
						<el-form-item label="新手机号：" prop="phone" :rules="[{ required: true, message: '请输入新手机号'}]">
							<el-input  v-model="changePhoneForm.phone" placeholder="请输入新手机号"></el-input>
						</el-form-item>
						<el-form-item label="短信验证码:" prop="code" :rules="[{ required: true, message: '请输入验证码'}]">
							<div class="verify-wrapper">
								<el-input type="text" maxlength="6" placeholder="请输入验证码" v-model="changePhoneForm.code" style="width: 350px;"></el-input>
								<el-button class="btn-orange" :disabled="disabled" @click="getCode">{{ valiBtn }}</el-button>
							</div>
						</el-form-item>
						
						
						<el-form-item>
							<el-button type="primary" class="qux" @click="closebox()">取消</el-button>
							<el-button type="primary" class="queding" @click="submitForm('changePhoneForm')">确定</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Bus from '../../common/bus.js';
export default {
	data() {
		return {
			dialogVisible: false,
			changePhoneForm: {
				phone: '',
				code:''
			},
			oldPhone:'',
			valiBtn: '获取验证码',
			disabled: false,
			
			
		};
	},
	mounted() {
		Bus.$on('phonestuts', data => {
			this.dialogVisible = data.show
			this.oldPhone = data.config.phone
		});
	},
	methods: {
		closebox() {
			this.dialogVisible = false;
			// Bus.$emit('reportdstuts', false)
		},
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$api.changePhone('post',this.changePhoneForm).then(()=>{
						this.$message({
							message: '操作成功',
							type: 'success'
						});
						this.dialogVisible=false
					})
				} else {
					return false;
				}
			});
		},
		//获取验证码 并只验证手机号 是否正确
		getCode() {
			this.$refs['changePhoneForm'].validateField('phone', err => {
				if (err) {
					return;
				} else {
					let fd = {
						phone:this.changePhoneForm.phone,
						type:0
					}
					this.$api.phonemsg('post',fd).then(()=>{
						this.tackBtn()
					})
				}
			});
		},
		tackBtn() {
			//验证码倒数60秒
			let time = 60;
			let timer = setInterval(() => {
				if (time == 0) {
					clearInterval(timer);
					this.valiBtn = '获取验证码';
					this.disabled = false;
				} else {
					this.disabled = true;
					this.valiBtn = time + '秒后重发';
					time--;
				}
			}, 1000);
		},
		
		
		
		
	}
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
	padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
	position: absolute;
	top: -40px;
	right: 0;
	font-size: 26px;
	color: #f3f3f3;
}
.details {
	padding-bottom: 20px;
	.wb-tit {
		padding: 17px 0;
		text-align: center;
		background-color: #f3f3f3;
		color: #000000;
		font-weight: 600;
	}
}

.updatabox {
	.demo-ruleForm {
		padding: 50px 80px 0px 50px;
		/deep/ .el-input__inner,
		/deep/ .el-textarea__inner {
			border-radius: 0px;
		}
		.qux{
			border-radius: 0px;
				width: 210px;
				// margin-top: 50px;
				background: #FFFFFF;
				border: 1px solid #00bcff;
				color: #00bcff;
				margin-right: 10px;
		}
		.queding{
			border-radius: 0px;
				width: 210px;
				// margin-top: 50px;
				background: #00bcff;
				border: none;
		}
		/deep/ .el-input-group__append,
		/deep/ .el-input-group__prepend {
			border-radius: 0 !important;
			background-color: #dddddd;
			color: #333333;
		}
	}
}


.verify-wrapper{
	display: flex;
	align-items: center;
	.btn-orange{
		width: 200px;
		padding: 13px 20px;
		background-color: #00BCFF;
		border: none;
		color: #FFFFFF;
		margin-left: 30px;
		border-radius: 0;
	}
}
</style>
