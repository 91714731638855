<template>
	<div class="">
		<el-dialog :visible.sync="dialogVisible" width="700px" :show-close="false" :modal-append-to-body="false" :lock-scroll="false">
			<div class="details">
				<span class="closeicon" @click="closebox()"><i class="el-icon-error"></i></span>
				<div class="wb-tit font18">选择第三方简历</div>
				<div class="updatabox">
					<div class="up-one">
						<div class="uo-item" @click="toup(1)" style="align-items: center; margin: 15px;">
							<img src="../../../assets/images/mycenter/tupian.png" >
							<div class="font16" style="margin-top: 30px">
                支持格式：51job简历图片、拉勾简历图片、猎聘DOC简历文件
              </div>
<!--							<div class="font14">-->
<!--								支持51j0b简历图片、拉钩简历图片-->
<!--							</div>-->
						</div>
						<div class="uo-item" @click="toup(2)" v-if="false">
							<img src="../../../assets/images/mycenter/pdf.png" >
							<div class="font18">
								DOC简历文件
							</div>
							<div class="font14">
								支持猎聘网DOC简历文件
							</div>
						</div>
						<div class="uo-item" @click="toup(3)" style="align-items: center; margin: 15px;">
							<img src="../../../assets/images/mycenter/lianjie.png" >
							<div class="font16" style="margin-top: 30px">
                支持格式：BOSS直聘在线简历、智联招聘在线简历
              </div>
<!--							<div class="font14" style="text-align: center">-->
<!--								支持BOSS直聘和智联招聘在线简历上传附件简历-->
<!--							</div>-->
						</div>
					</div>
					<div class="tishi font14">
						<div class="t-tit">
							温馨提示：
						</div>
						<div class="t-content">
              1.本功能旨在简化您的信息填写步骤，识别可能存在误差，生成内容请检查后发布。<br>
              2.非上述格式的简历无法正常识别，请勿上传。请在我的-我的简历直接填写，谢谢。
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Bus from '../../../common/bus.js';
export default {
	data() {
		return {
			dialogVisible: false,
		};
	},
	mounted() {
		Bus.$on('choosefstuts', data => {
			this.dialogVisible = data;

		});
	},
	methods: {
		closebox() {
			this.dialogVisible = false;
			// Bus.$emit('reportdstuts', false)
		},
		toup(type){
			this.closebox()
			Bus.$emit('resumestuts', {
				show:true,
				type:type
			})
		}






	}
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
	padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
	position: absolute;
	top: -40px;
	right: 0;
	font-size: 26px;
	color: #f3f3f3;
}
.details {
	padding-bottom: 0px;
	.wb-tit {
		padding: 17px 0;
		text-align: center;
		background-color: #f3f3f3;
		color: #000000;
		font-weight: 600;
	}
}

.updatabox {
	padding: 40px 30px;
	.up-one{
		display: flex;
		align-items: center;
		justify-content: center;
		.uo-item:nth-of-type(1){
			margin-left: 0;
		}
		.uo-item{
			display: flex;
			flex: 1;
			flex-direction: column;
			background-color: #FFFFFF;
			box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
			margin-left: 10px;
			padding: 20px;
			height: 150px;
			// line-height: 2;
			img{
				width: 45px;
				height: 55px;
			}
			div{
				margin-top: 30px;
				color: #111111;
			}
			div:last-child{
				margin-top: 10px;
				color: #999999;
			}
		}
	}
}
.tishi{
	margin-top: 30px;
	.t-tit{
		color: #FF4444;
	}
	.t-content{
		margin-top: 20px;
		color: #999999;
	}
}


</style>
